export const albums_data = {
   "arkea-ultim-challenge-2024": {
      "name": "Arkea Ultim Challenge 2024",
      "photos": [
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0093.jpg",
            "slug": "20240107-0093-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0093-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0093-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0093-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0093-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0093-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0093-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0094.jpg",
            "slug": "20240107-0094-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0094-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0094-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0094-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0094-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0094-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0094-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0097.jpg",
            "slug": "20240107-0097-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0097-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0097-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0097-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0097-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0097-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0097-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0099.jpg",
            "slug": "20240107-0099-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0099-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0099-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0099-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0099-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0099-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0099-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0103.jpg",
            "slug": "20240107-0103-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0103-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0103-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0103-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0103-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0103-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0103-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0104.jpg",
            "slug": "20240107-0104-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0104-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0104-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0104-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0104-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0104-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0104-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0105.jpg",
            "slug": "20240107-0105-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0105-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0105-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0105-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0105-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0105-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0105-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0107.jpg",
            "slug": "20240107-0107-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0107-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0107-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0107-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0107-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0107-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0107-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0109.jpg",
            "slug": "20240107-0109-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0109-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0109-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0109-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0109-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0109-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0109-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0111.jpg",
            "slug": "20240107-0111-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0111-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0111-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0111-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0111-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0111-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0111-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0113.jpg",
            "slug": "20240107-0113-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0113-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0113-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0113-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0113-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0113-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0113-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0115.jpg",
            "slug": "20240107-0115-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0115-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0115-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0115-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0115-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0115-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0115-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0120.jpg",
            "slug": "20240107-0120-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0120-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0120-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0120-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0120-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0120-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0120-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0122.jpg",
            "slug": "20240107-0122-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0122-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0122-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0122-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0122-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0122-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0122-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 710,
            "name": "20240107_0123.jpg",
            "slug": "20240107-0123-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0123-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x673_20240107-0123-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x710_20240107-0123-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0123-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x525_20240107-0123-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0123-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 721,
            "name": "20240107_0126.jpg",
            "slug": "20240107-0126-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0126-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x683_20240107-0126-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0126-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0126-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x534_20240107-0126-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0126-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 721,
            "name": "20240107_0128.jpg",
            "slug": "20240107-0128-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0128-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x683_20240107-0128-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0128-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0128-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x534_20240107-0128-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0128-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 721,
            "name": "20240107_0133.jpg",
            "slug": "20240107-0133-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0133-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x683_20240107-0133-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0133-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0133-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x534_20240107-0133-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0133-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 721,
            "name": "20240107_0135.jpg",
            "slug": "20240107-0135-jpg",
            "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0135-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1024x683_20240107-0135-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/1080x721_20240107-0135-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0135-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/arkea-ultim-challenge-2024/800x534_20240107-0135-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x333_20240107-0135-jpg.jpg",
            "width": 1080
         }
      ],
      "slug": "arkea-ultim-challenge-2024",
      "src": "/static/_gallery/albums/arkea-ultim-challenge-2024/499x328_20240107-0093-jpg.jpg"
   }
};